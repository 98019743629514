import { useTranslation } from "react-i18next";
import { useAuth } from "../../../Auth/AuthProvider";
import { useEffect, useReducer, useState } from "react";
import { EditRowModal, RowClickModal } from "../../Modals/RowClickModal";
import TableImgModal from "../../Modals/TableImgModal";
import { EditCondition } from "../../Modals/EditCondition";
import { VehicleTable } from "./VehicleTable";
import { IsRouteActive } from "../../Navigation/NavFunctions";
import { EditBacklogRow } from "../../Modals/EditBacklogRow";
import { AdvancedSearch } from "../../Utility/AdvancedSearch";
import { DisplayFilters, filterVehicleData } from "./Filters";

const reducer = (state, action) => {
  switch (action.type) {
    case "EDIT_ROW":
      return {
        ...state,
        editRow: true,
      };
    case "CLOSE_EDIT_ROW":
      return {
        ...state,
        editRow: false,
      };
    case "OPEN_DOCS":
      return {
        ...state,
        docsModal: true,
      };
    case "CLOSE_DOCS":
      return {
        ...state,
        docsModal: false,
      };
    default:
      break;
  }
};

const initialState = {
  editRow: false,
  docsModal: false,
  advancedSearch: false,
};
export const TableConst = ({
  state,
  dispatch,
  headers,
  type,
  advancedSearch,
  handleAdvancedSearch,
  filters,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [modalState, modalDispatch] = useReducer(reducer, initialState);
  const [allFilters, setFilters] = useState(filters);
  const [newData, setData] = useState(state.data);

  useEffect(() => {
    if (allFilters && allFilters?.length > 0) {
      setData(filterVehicleData(allFilters, state.data, type));
    }
  }, [allFilters]);

  const closeModal = () => dispatch({ type: "CLOSE_SELECTED_ROW" });
  return (
    <>
      {state.selectedRow &&
        (user.groups.some((group) =>
          ["Admin", "Safety", "Night Dispatch", "Mechanic"].includes(group.name)
        ) && type !== "visitors" ? (
          <RowClickModal
            setEditRow={() => modalDispatch({ type: "EDIT_ROW" })}
            setDocuments={() => modalDispatch({ type: "OPEN_DOCS" })}
            row={state.selectedRow}
            type={type}
            close={closeModal}
            english={t}
          />
        ) : (
          <TableImgModal
            info={
              type === "trailers"
                ? state.selectedRow.trailer.trailer_number
                : type === "trucks"
                ? state.selectedRow.truck.truckName
                : state.selectedRow.visitor.driverName
            }
            trailer={state.selectedRow}
            english={t}
            close={closeModal}
          />
        ))}
      {modalState.editRow &&
        (user.groups.some((group) => group.name === "Mechanic") ? (
          <EditCondition
            english={t}
            close={() => modalDispatch({ type: "CLOSE_EDIT_ROW" })}
            row={state.selectedRow}
            type={type}
          />
        ) : IsRouteActive("/backlog") ? (
          <EditBacklogRow
            row={state.selectedRow}
            close={() => modalDispatch({ type: "CLOSE_EDIT_ROW" })}
          />
        ) : (
          <EditRowModal
            row={state.selectedRow}
            english={t}
            close={() => modalDispatch({ type: "CLOSE_EDIT_ROW" })}
            type={type}
          />
        ))}
      {modalState.docsModal && (
        <TableImgModal
          info={
            type === "trailers"
              ? state.selectedRow.trailer.trailer_number
              : type === "trucks"
              ? state.selectedRow.truck.truckName
              : `${state.selectedRow.visitor.first_name} ${state.selectedRow.visitor.last_name}`
          }
          trailer={state.selectedRow}
          english={t}
          close={() => modalDispatch({ type: "CLOSE_DOCS" })}
        />
      )}
      {state.advancedSearch && (
        <AdvancedSearch
          closeAdvancedSearchForm={() => dispatch("CLOSE_ADVANCED_SEARCH")}
          vehicleType={type}
          handleAdvancedSearch={handleAdvancedSearch}
        />
      )}
        <DisplayFilters
          filters={allFilters}
          setFilters={setFilters}
          dispatch={dispatch}
          advancedSearch={advancedSearch}
        />
      <VehicleTable
        headers={headers}
        data={newData}
        dispatch={dispatch}
        type={type}
      />
    </>
  );
};
